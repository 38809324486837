'use strict';

import '../css/style.css';
import 'picturefill/dist/picturefill';

import Alpine from 'alpinejs'
import stickybits from 'stickybits';
import system from './store/system';
import translator from './store/translator';
import storagePersistenceManager from './store/storagePersistenceManager';
import newsList from './components/newsList'

import './components/mainNav';
import './components/legacy';
import './components/lightBox';
import './components/accordion';
import './components/responsiveTable';
import './components/swiperSlideshow';
import './components/mobileMenuStatus';
import './components/shop/productStorageForm';
import './components/shop/productStorageUpdater';
import './components/shop/productStorageWidget';
import './components/shop/quantitySelector';
import './components/shop/checkoutForm';
import './cookies';

import './helper/anchorScroll';
import './components/shop/priceFetcher';

window.Alpine = Alpine;
window.newsList = newsList;

Alpine.store('system', system());
Alpine.store('translator', translator());
Alpine.store('storagePersistenceManager', storagePersistenceManager());

Alpine.start();

// NO!!
newsList().init();
// NO!! (not here?)
stickybits('header.header-stickybits');
