import Swal from 'sweetalert2'
import Alpine from 'alpinejs';
import closeButtonSvg from '../../images/icons/close.svg';

export default () => {

    return {

        system: Alpine.store('system'),
        translator: Alpine.store('translator'),

        persistWishlistItems(wishlistItems, $triggerElement) {

            let ajaxUrl = this.system.getSystemVar('ajaxUrl');

            if ($triggerElement) {
                $triggerElement.setAttribute('disabled', 'disabled');
            }

            return new Promise((resolve, reject) => {

                // todo: allow multiple item submission!!
                let wishlistItem = wishlistItems[0];

                fetch(ajaxUrl, {
                    method: 'POST',
                    body: JSON.stringify({
                        proxyController: 'Wishlist',
                        proxyAction: 'toggleItem',
                        product: wishlistItem.productId,
                        quantity: wishlistItem.quantity,
                        overrideQuantity: wishlistItem.overrideQuantity
                    }),
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                    .then(res => res.text())
                    .then(data => {
                        let responseData = JSON.parse(data);

                        if ($triggerElement) {
                            $triggerElement.setAttribute('disabled', false);
                        }

                        if (responseData.success === false) {
                            reject(responseData);
                            return;
                        }

                        resolve(responseData);

                    })
                    .catch(responseData => {

                        if ($triggerElement) {
                            $triggerElement.setAttribute('disabled', false);
                        }

                        reject(responseData);
                    });
            });

        },

        persistCartItems(items, $triggerElement) {

            let locale = this.system.getSystemVar('language'),
                okLabel = this.translator.trans('website', 'coreshop.ui.modal_dialog.ok'),
                continueShoppingLabel = this.translator.trans('website', 'coreshop.ui.continue_shopping'),
                goToCartLabel = this.translator.trans('website', 'coreshop.ui.go_to_cart'),
                cartUrl = this.system.getSystemVar('cartUrl');

            if ($triggerElement) {
                $triggerElement.setAttribute('disabled', 'disabled');
            }

            return new Promise((resolve, reject) => {

                fetch('/' + locale + '/shop/cart/proxy-multiple-add', {
                    method: 'POST',
                    body: JSON.stringify({
                        items: items
                    }),
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                })
                    .then(res => res.text())
                    .then(data => {
                        let responseData = JSON.parse(data);

                        if ($triggerElement) {
                            $triggerElement.setAttribute('disabled', false);
                        }

                        if (responseData.success === true) {

                            Swal.fire({
                                html: responseData.html,
                                showCancelButton: true,
                                showConfirmButton: true,
                                showCloseButton: true,
                                confirmButtonText: goToCartLabel,
                                cancelButtonText: continueShoppingLabel,
                                reverseButtons: true,
                                closeButtonHtml: `<img class="text-black w-6 h-6" src="${closeButtonSvg}">`,
                                buttonsStyling: false,
                                customClass: {
                                    htmlContainer: 'text-left',
                                    popup: 'rounded-none',
                                    cancelButton: 'btn secondary small w-full mx-5 md:w-auto md:mx-0 md:mr-5 mb-5 md:mb-0',
                                    confirmButton: 'btn primary small w-full mx-5 md:w-auto md:mx-0',
                                }
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    window.location.href = cartUrl
                                    return false;
                                }
                            });

                            resolve(responseData);

                        } else {
                            Swal.fire({
                                html: responseData.errors.join('<br/>'),
                                buttonsStyling: false,
                                confirmButtonText: okLabel,
                                customClass: {
                                    confirmButton: 'btn primary small',
                                }
                            });

                            reject(responseData);
                        }

                    })
                    .catch(responseData => {

                        if ($triggerElement) {
                            $triggerElement.setAttribute('disabled', false);
                        }

                        Swal.fire({
                            html: 'An Error occurred.',
                            buttonsStyling: false,
                            confirmButtonText: okLabel,
                            customClass: {
                                confirmButton: 'btn primary small',
                            }
                        });

                        reject(responseData);
                    });
            });
        }
    };
}
