import Alpine from 'alpinejs';

Alpine.data('productStorageForm', (type) => ({

    type: type,

    init() {
        // do nothing
    },

    submit() {
        if (this.type === 'cart') {
            this.submitCart();
        } else {
            this.submitWishlist()
        }
    },

    /**
     * CARTS BELOW
     */

    submitCart() {

        let $triggerElement = this.$el.querySelector('button'),
            $amountField = this.$el.querySelector('input.cart-item-quantity'),
            $configurableOptionsWrapper = this.$el.querySelector('[id$="cartItem_configurableOptions"]'),
            minOrderAmount = this.parseNumericAmount($amountField.dataset.minOrderAmount, 1),
            productId = $amountField.dataset.productId,
            unitDefinition = this.$el.querySelector('input[name*="unitDefinition"]'),
            unitDefinitionValue = unitDefinition ? unitDefinition.value : null,
            amount = parseFloat($amountField.value),
            configurableOptions = null,
            valid = true,
            cartItem;

        if (amount < minOrderAmount) {
            valid = false;
        }

        if ($configurableOptionsWrapper) {
            configurableOptions = [];
            $configurableOptionsWrapper.querySelectorAll('fieldset').forEach(fieldSet => {

                let configurableOptionsBlock = {};

                $(this).querySelectorAll('select,textarea,input[type="checkbox"]:checked,input[type="text"],input[type="number"]').forEach(element => {
                    let itemName = null;

                    element.getAttribute('name').replace(/\[(.*?)\]/g, function (g0, g1) {
                        itemName = g1;
                    });

                    if (itemName !== null) {
                        configurableOptionsBlock[itemName] = element.value;
                    }
                });

                configurableOptions.push(configurableOptionsBlock);
            })
        }

        if (valid !== true) {
            return;
        }

        cartItem = {
            quantity: amount,
            purchasable: productId,
            unitDefinition: unitDefinitionValue
        };

        if (configurableOptions !== null) {
            cartItem.configurableOptions = configurableOptions;
        }

        this.$store.storagePersistenceManager.persistCartItems([{cartItem: cartItem}], $triggerElement).then(
            (success) => {
                this.$dispatch('refresh-storage', this.type);
            },
            (error) => {
                console.log(error)
            }
        )
    },


    /**
     * BOOKMARKS BELOW
     */

    submitWishlist() {

        let $triggerElement = this.$el.querySelector('button'),
            amountField = this.$el.querySelector('input.cart-item-quantity'),
            quantity = parseFloat(amountField.value),
            productId = amountField.dataset.productId,
            items = [];

        items.push({
            quantity: quantity,
            productId: productId,
            overrideQuantity: false,
        });

        this.$store.storagePersistenceManager.persistWishlistItems(items, $triggerElement).then(
            (responseData) => {

                this.$dispatch('wishlist-item-added');

                if (responseData.updateCounter === false) {
                    return;
                }

                this.$dispatch('refresh-storage', this.type);
            },
            (error) => {
                console.log(error)
            }
        )
    },


    /**
     * HELPER BELOW
     */

    parseNumericAmount(val, defaultValue) {

        if (val === undefined) {
            return defaultValue;
        }

        if (val === null) {
            return defaultValue;
        }

        if (typeof val === 'string') {
            return parseFloat(val.replace(',', '.'));
        }

        return val;
    }
}));
