import Alpine from 'alpinejs';

Alpine.data('accordion', (quantityItems = 2, closeOther = false, initAllOpen = false, initFirstOpen = false, handleTabs = false, editMode = false) => ({

    quantityItems: quantityItems,
    initAllOpen: initAllOpen,
    initFirstOpen: initFirstOpen,
    itemState: [],
    isTabMode: false,
    editMode: editMode,

    init() {

        if(editMode === true) {
            return;
        }

        if(this.$store.system.editMode){
            return;
        }

        if (handleTabs) {
            this.updateTabMode();
            this.checkTabModeChange();
        }

        if (this.initAllOpen) {
            for (let i = 0; i < this.quantityItems; i++) {
                this.itemState[i] = true;
                this.configureItemAfterInitialOption(this.getContainerElement(i));
            }

        } else if (this.initFirstOpen) {
            this.itemState[0] = true;
            this.configureItemAfterInitialOption(this.getContainerElement(0));
        }
    },

    accordionClick(itemId) {

        for (let i = 0; i < this.quantityItems; i++) {
            const containerElement = this.getContainerElement(i);

            if (!containerElement) {
                return;
            } else if (!this.isTabMode && itemId === i && this.isOpen(itemId)) {
                this.closeItem(containerElement, i);
            } else if (itemId === i) {
                this.openItem(containerElement, i);
            } else if (closeOther) {
                this.closeItem(containerElement, i);
            }
        }
    },

    configureItemAfterInitialOption(containerElement) {

        let self = this;

        if (!containerElement) {
            return;
        }

        setTimeout(function(){

            self.openItem(containerElement);
            containerElement.classList.add('max-h-0');
        }, 50);

    },

    isOpen(itemId) {
        return this.itemState[itemId];
    },

    openItem(containerElement, itemId) {
        this.itemState[itemId] = true;
        const containerHeight = containerElement.scrollHeight + 'px';
        containerElement.style.setProperty('max-height', containerHeight);
    },

    closeItem(containerElement, itemId) {
        this.itemState[itemId] = false;
        containerElement.style.setProperty('max-height', '');
    },

    getContainerElement(itemId) {
        const containerElement = this.$refs[this.getContainerName(itemId)];

        if (!containerElement) {
            console.error('Error happened on accordion, container not set: ' + this.getContainerName(itemId));
            return null;
        } else {
            return containerElement;
        }
    },

    getContainerName(itemId) {
        return 'container' + itemId;
    },

    updateTabMode() {

        if (window.matchMedia('(min-width: 768px)').matches) {
            this.isTabMode = true;
        } else {
            this.isTabMode = false;
        }

    },

    checkTabModeChange() {
        let resizeTimer;

        window.addEventListener('resize', () => {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(() => this.updateTabMode(), 250);
        });
    }
}));