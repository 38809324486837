import 'lightgallery.js';
import 'lightgallery.js/dist/css/lightgallery.min.css';
import Alpine from 'alpinejs';

Alpine.data('lightBox', (selector = '') => ({

    selector: selector,

    init() {
        window.lightGallery(this.$el, {
            download: false,
            counter: false,
            selector: this.selector
        });
    }
}));
