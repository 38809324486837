import Alpine from 'alpinejs';
import Swal from 'sweetalert2';
import loaderImg from '../../../images/loader.gif';

Alpine.data('checkoutForm', () => ({

    translator: Alpine.store('translator'),

    init() {
        // preload loader image
        let loader = new Image();
        loader.src = loaderImg;
    },

    submit() {
        Swal.fire({
            html: `<p>${this.translator.trans('website', 'coreshop.ui.checkout_processing')}</p><img class="inline w-12 h-12" src="${loaderImg}" />`,
            allowOutsideClick: false,
            showCancelButton: false,
            showConfirmButton: false,
            showCloseButton: false,
            customClass: {
                htmlContainer: 'text-center',
                popup: 'rounded-none',
            }
        })
    }
}));
