document.addEventListener('DOMContentLoaded', () => {
    const hash = window.location.hash;

    // If initially there's a hash in the URL, scroll to the position
    if (hash && hash.length) {
        const elScrollTo = document.getElementById(hash.substring(1));

        setTimeout(() => {
            if (elScrollTo) {
                scrollToElement(elScrollTo);
            }
        }, 100);
    }

    document.querySelectorAll('a[href*="#"]').forEach(link => {
        link.addEventListener('click', e => {
            e.preventDefault();

            const href = link.getAttribute('href');
            const elScrollTo = document.getElementById(href.substring(1));

            if (elScrollTo) {
                window.location.hash = href;
                scrollToElement(elScrollTo);
            }
        })
    })
});

/**
 * Scrolls the window to the given element (smoothly).
 * @param {Element} el
 * @param {Number} offset
 */
const scrollToElement = (el, offset = 20) => {
    const header = document.querySelector('[x-id="header"]') || document.querySelector('header');

    window.scrollTo({
        top: el.getBoundingClientRect().top + document.documentElement.scrollTop - header.offsetHeight - offset,
        behavior: 'smooth'
    });
};