import Alpine from 'alpinejs';

Alpine.data('legacy', () => ({
    init() {

        if (window.CSS && CSS.supports('color', 'var(--primary)')) {
            this.$el.classList.add('custom-properties');
        } else {
            this.$el.classList.add('custom-properties-disabled');
        }

        // Objectfit
        if ('objectFit' in document.documentElement.style === false) {

            const objectFitPolify = (item) => {
                let imgUrl = item.src;

                if (imgUrl) {
                    item.classList.add('compat-object-fit');
                    item.style.backgroundImage = 'url(' + imgUrl + ')';
                }
            }

            document.querySelectorAll('.object-fit-legacy').forEach(function (item) {
                objectFitPolify(item);
            });
        }
    }
}));