import Alpine from 'alpinejs';

Alpine.data('mobileMenuStatus', () => ({

    showMobileMenu: false,

    init() {
        this.$watch('showMobileMenu', status => this.bodyOverflow(status));
    },

    bodyOverflow(status) {
        if (status === true) {
            document.body.classList.add('overflow-hidden');
        } else {
            document.body.classList.remove('overflow-hidden');
        }
    }
}));
