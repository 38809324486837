import Alpine from 'alpinejs';

Alpine.data('productStorageWidget', (type) => ({

    type: type,
    title: null,

    init() {
        // do nothing
    },

    refresh($event) {
        if (this.type === 'cart') {
            this.refreshCartWidget();
        } else {
            this.refreshWishlistWidget();
        }
    },

    refreshWishlistWidget() {

        // ????
        let up = true;

        let cartBadge = this.$el.querySelector('.cart-badge'),
            cartBadgeCounter;

        if (cartBadge.classList.contains('invisible')) {
            cartBadge.classList.remove('invisible');
            cartBadge.classList.add('visible');

            cartBadgeCounter = 0;
        } else {
            cartBadgeCounter = parseInt(cartBadge.innerHTML.trim());
        }

        if (up === true) {
            cartBadgeCounter++;
        } else {
            cartBadgeCounter--;
        }

        if (cartBadgeCounter === 0) {
            cartBadge.classList.add('invisible');
            cartBadge.classList.remove('visible');
        } else {
            cartBadge.innerHTML = cartBadgeCounter;
        }

    },

    refreshCartWidget() {

        let $cartWidgetWrapper = this.$el.parentElement,
            $cartWidgetItemBadge = this.$el.querySelector('.cart-badge'),
            $cartWidgetProducts = this.$el.nextElementSibling,
            locale = this.$store.system.getSystemVar('language');

        $cartWidgetWrapper.classList.add('loading');

        fetch('/' + locale + '/shop/cart/widget/', {
            method: 'GET',
        })
        .then(res => res.text())
        .then(data => {

            let cartWidgetNode, newCount, newProductNode

            cartWidgetNode = document.createElement('div');
            cartWidgetNode.innerHTML = data;

            newCount = cartWidgetNode.firstElementChild.dataset.count;
            newProductNode = cartWidgetNode.querySelector('.cart-widget-products');

            $cartWidgetWrapper.classList.remove('loading');

            if (newCount > 0) {
                $cartWidgetItemBadge.innerHTML = newCount;
                $cartWidgetItemBadge.classList.remove('invisible');
            }

            $cartWidgetProducts.innerHTML = newProductNode.innerHTML;
        });
    }
}));
