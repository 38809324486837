import Alpine from 'alpinejs';

Alpine.data('productStorageUpdater', (type, productId, selectedQuantity) => ({

    type: type,
    productId: productId,
    selectedQuantity: parseInt(selectedQuantity),

    add() {
        this.selectedQuantity++;
        this.updateQuantity();
    },

    remove() {

        if (this.selectedQuantity === 1) {
            return;
        }

        this.selectedQuantity--;
        this.updateQuantity();
    },

    change() {

        this.selectedQuantity = parseInt(this.selectedQuantity);

        if (this.selectedQuantity === 0) {
            this.selectedQuantity = 1;
        }

        this.updateQuantity();
    },

    updateQuantity() {

        if (this.type === 'cart') {
            this.updateCartItemQuantity();
        } else if (this.type === 'wishlist') {
            this.updateBookmarkItemQuantity()
        }

    },

    updateCartItemQuantity() {
        throw new Error('updateCartItemQuantity is not implemented');
    },

    updateBookmarkItemQuantity() {

        let items = [], promise;

        items.push({
            quantity: this.selectedQuantity,
            productId: this.productId,
            overrideQuantity: true,
        });

        promise = this.$store.storagePersistenceManager.persistBookmarkItems(items, this.$el)
    }
}));
