import Alpine from 'alpinejs';

Alpine.data('mainNav', () => ({

    init() {

        let mainNav = this.$el,
            self = this,
            flyoutWraps = mainNav.querySelectorAll('.flyout-menu-wrap');

        this.detectOrientation(flyoutWraps);

        window.addEventListener('resize', function () {
            self.detectOrientation(flyoutWraps);
        });

        window.addEventListener('DOMContentLoaded', (event) => {
            self.sameFlyOutUlHeight(flyoutWraps)
        });

        if (this.detectTouchDevice()) {
            document.addEventListener('click', (event) => this.triggerFlyoutWrap(event), {passive: false});
            document.addEventListener('touchstart', (event) => this.triggerFlyoutWrap(event), {passive: false});
        }

    },

    detectOrientation(flyoutWraps) {

        flyoutWraps.forEach(wrap => {

            let uls = wrap.querySelectorAll('.level-2');

            uls.forEach(ul => {

                let freeRightSpace = window.innerWidth - ul.getBoundingClientRect().right;

                if (freeRightSpace < ul.getBoundingClientRect().width) {
                    ul.classList.add('orientation-left');
                } else {
                    ul.classList.remove('orientation-left');
                }

            });

        });

    },

    setMainNavContainerHeight(wrap, maxHeight) {

        let firstLevelUl = wrap.querySelector('ul'),
            secondLevelUls = firstLevelUl.querySelectorAll('ul'),
            wrapStyle = getComputedStyle(wrap),
            height = maxHeight + 'px';

        wrap.style.height = (maxHeight + parseInt(wrapStyle.paddingTop) + parseInt(wrapStyle.paddingBottom)) + 'px';

        if (firstLevelUl) {
            firstLevelUl.style.height = height;
        }

        secondLevelUls.forEach(item => {
            item.style.height = height;
        });

    },

    triggerFlyoutWrap(event) {
        const flyoutTargetItems = document.querySelectorAll('.flyout-menu-item'),
            subFlyoutTargetItems = document.querySelectorAll('.flyout-menu-item .flyout-menu-wrap ul li'),
            megaMenuItems = document.querySelectorAll('.mega-menu-item'),
            subMegaMenuItems = document.querySelectorAll('.mega-menu-item .mega-menu-wrap ul li'),
            flyOutClasses = ['flyout-menu-item', 'mega-menu-item'],
            activeClass = 'is-open';

        let parentElement = event.target.parentElement;

        if (this.checkRecursiveParentElements(parentElement, flyOutClasses)) {
            if (this.checkIfElementHasClasses(parentElement, flyOutClasses)) {
                if (parentElement.classList.contains(activeClass)) {
                    window.location.href = event.target.attributes.href.value;
                } else {
                    this.openLogic(
                        parentElement,
                        activeClass,
                        event,
                        event.target,
                        [flyoutTargetItems, subFlyoutTargetItems, megaMenuItems, subMegaMenuItems],
                        false
                    );
                }
            } else {
                const linkTag = this.getParentLinkTag(event.target);

                if (linkTag && linkTag.parentElement) {
                    parentElement = linkTag.parentElement;

                    if (parentElement.querySelector('ul')) {
                        if (parentElement.querySelector('ul').classList.contains('product-grid')) {
                            if (parentElement.classList.contains(activeClass)) {
                                window.location.href = event.target.attributes.href.value;
                            } else {
                                this.openLogic(
                                    parentElement,
                                    activeClass,
                                    event,
                                    linkTag,
                                    [flyoutTargetItems, subFlyoutTargetItems, megaMenuItems, subMegaMenuItems],
                                    true,
                                    5
                                );
                            }
                        } else {
                            event.preventDefault();

                            if (linkTag.classList.contains('has-child')) {
                                this.openLogic(
                                    parentElement,
                                    activeClass,
                                    event,
                                    linkTag,
                                    [flyoutTargetItems, subFlyoutTargetItems, megaMenuItems, subMegaMenuItems],
                                    true,
                                    3
                                );
                            }
                        }
                    }
                }
            }
        } else {
            this.closeFlyOutWraps([flyoutTargetItems, subFlyoutTargetItems, megaMenuItems, subMegaMenuItems], activeClass);
        }
    },

    getParentLinkTag(element) {
        if (element.nodeName === 'A') {
            return element;
        } else {
            if (element.parentElement) {
                return this.getParentLinkTag(element.parentElement);
            }
        }
    },

    checkRecursiveParentElements(parentElement, classes) {
        if (parentElement) {
            if (this.checkIfElementHasClasses(parentElement, classes)) {
                return true;
            } else {
                return this.checkRecursiveParentElements(parentElement.parentElement, classes);
            }
        } else {
            return false;
        }
    },

    checkIfElementHasClasses(element, classes) {
        let containsClass = false;

        classes.forEach(itemClass => {
            if (!containsClass) {
                if (element.classList.contains(itemClass)) {
                    containsClass = true;
                }
            }
        });

        return containsClass;
    },

    detectTouchDevice() {
        return (
            ('ontouchstart' in window) ||
            (navigator.maxTouchPoints > 0) ||
            (navigator.msMaxTouchPoints > 0)
        );
    },

    closeFlyOutWraps(items, activeClass) {
        items.forEach(item => {
            item.forEach(item => {
                if (item.classList.contains(activeClass)) {
                    item.classList.remove(activeClass)
                }
            });
        });
    },

    openLogic(parentElement, activeClass, event, linkTag, closingElements, isSub, parentFlyOutCount) {
        if (parentElement.classList.contains(activeClass)) {
            window.location.href = linkTag.attributes.href.value;
        } else {
            event.preventDefault();

            this.closeFlyOutWraps(closingElements, activeClass);

            if (isSub) {
                let parentFlyOutWrap = parentElement;

                for (let i = 0; i < parentFlyOutCount; i++) {
                    parentFlyOutWrap = parentFlyOutWrap.parentElement;
                }

                parentFlyOutWrap.classList.add(activeClass);
            }

            parentElement.classList.add(activeClass);
        }
    },

    sameFlyOutUlHeight(flyoutWraps) {

        flyoutWraps.forEach(wrap => {

            let uls = wrap.querySelectorAll('ul'),
                maxHeight = 0;

            uls.forEach(ul => {
                if (ul.offsetHeight > maxHeight) {
                    maxHeight = ul.offsetHeight;
                }
            });

            if (maxHeight > 0) {
                this.setMainNavContainerHeight(wrap, maxHeight)
            }

        });

    },


}));
