import system from './system';

export default () => {

    return {
        translations: system().getSystemVar('translations'),

        trans(module, key) {
            if (this.translations[module][key]) {
                return this.translations[module][key];
            }

            return null;
        }
    }
}