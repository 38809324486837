import Isotope from 'isotope-layout';

export default () => {
    return {
        init() {

            const filterButtons = document.querySelectorAll('.news-filter-button');
            const loadMoreButtons = document.querySelectorAll('.news-loadmore-button');
            const newsList = document.querySelector('.news-list-entries');
            let getNewsRequest;

            if (typeof (newsList) === 'undefined' || newsList === null) {
                return;
            }

            const isotopeConfig = {
                itemSelector: '.news-entry',
                layoutMode: 'masonry'
            }

            let newsListIso = new Isotope(newsList, isotopeConfig);

            const getContent = () => {
                if (getNewsRequest.readyState === XMLHttpRequest.DONE) {
                    if (getNewsRequest.status === 200) {
                        const response = getNewsRequest.responseText;
                        let responseDocument = document.createElement('html');
                        responseDocument.innerHTML = response;
                        let newsItems = responseDocument.querySelectorAll('.news-list-entries > div');
                        newsList.innerHTML = '';
                        newsItems.forEach(item => {
                            newsList.appendChild(item);
                        });
                        newsListIso.destroy();
                        newsListIso = new Isotope(newsList, isotopeConfig);
                        newsListIso.layout();

                        loadMoreButtons.forEach(loadMoreBtn => {
                            loadMoreBtn.dataset.page = 2;
                            checkIfLoadMoreBtnIsNeeded(loadMoreBtn);
                        });
                    }
                }
            };

            const filterFunc = (btn) => {
                if (!btn.classList.contains('active')) {
                    filterButtons.forEach(btn => {
                        btn.classList.remove('active');
                    });
                    btn.classList.add('active');
                    getNewsRequest = new XMLHttpRequest();
                    if (getNewsRequest) {
                        getNewsRequest.onreadystatechange = getContent;
                        getNewsRequest.open('GET', btn.dataset.newspage);
                        getNewsRequest.responseType = 'text';
                        getNewsRequest.send();
                    }
                }
            }

            filterButtons.forEach(btn => {
                btn.addEventListener('click', () => filterFunc(btn));
            });

            const checkIfLoadMoreBtnIsNeeded = (btn) => {
                if (btn.classList.contains('already-loaded')) {
                    if (!document.querySelector('.news-list-items-' + btn.dataset.page)) {
                        if (!btn.parentElement.classList.contains('hidden')) {
                            btn.parentElement.classList.add('hidden');
                        }
                    } else {
                        if (btn.parentElement.classList.contains('hidden')) {
                            btn.parentElement.classList.remove('hidden');
                        }
                    }
                }
            }

            const loadMoreFunc = (btn) => {
                if (btn.classList.contains('already-loaded')) {
                    const itemElements = document.querySelector('.news-list-items-' + btn.dataset.page);
                    itemElements.classList.remove('hidden');
                    btn.dataset.page = parseInt(btn.dataset.page) + 1;
                    newsListIso.layout();
                    checkIfLoadMoreBtnIsNeeded(btn);
                }
            }

            loadMoreButtons.forEach(btn => {
                checkIfLoadMoreBtnIsNeeded(btn);
                btn.addEventListener('click', () => loadMoreFunc(btn));
            });
        }
    };
}
