export default () => {

    return {
        systemVars: null,

        get editMode() {
            return this.getSystemVar('editMode');
        },

        getSystemVar(variable) {
            if (!this.systemVars) {
                let dataSystemVars = document.querySelector('html').dataset.systemVars;
                this.systemVars = dataSystemVars !== undefined ? JSON.parse(dataSystemVars) : {};
            }

            if (variable === undefined) {
                return this.systemVars;
            }

            if (this.systemVars[variable] !== undefined) {
                return this.systemVars[variable];
            }

            throw new Error('systemVar "' + variable + '" has not been found.');
        },
    };
}
