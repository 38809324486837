(function () {
    const currentDomain = window.location.hostname;

    const cookieFirstScripts = {
        "cleanfix.com": "https://consent.cookiefirst.com/sites/cleanfix.com-22d9ec2a-3490-476b-80d1-e0bdb2fd6ffb/consent.js",
        "www.cleanfix.com": "https://consent.cookiefirst.com/sites/cleanfix.com-22d9ec2a-3490-476b-80d1-e0bdb2fd6ffb/consent.js",
        "cleanfix.ch": "https://consent.cookiefirst.com/sites/cleanfix.ch-39ba7120-820b-4517-a9f6-52c090f55b80/consent.js",
        "www.cleanfix.ch": "https://consent.cookiefirst.com/sites/cleanfix.ch-39ba7120-820b-4517-a9f6-52c090f55b80/consent.js",
        "cleanfix.at": "https://consent.cookiefirst.com/sites/cleanfix.at-d8a3b23c-8ad8-4771-bb65-3cfed47ba540/consent.js",
        "www.cleanfix.at": "https://consent.cookiefirst.com/sites/cleanfix.at-d8a3b23c-8ad8-4771-bb65-3cfed47ba540/consent.js",
        "cleanfix.de": "https://consent.cookiefirst.com/sites/cleanfix.de-feb4163d-57b0-4465-8792-e945fbad0844/consent.js",
        "www.cleanfix.de": "https://consent.cookiefirst.com/sites/cleanfix.de-feb4163d-57b0-4465-8792-e945fbad0844/consent.js",
        "cleanfix.fr": "https://consent.cookiefirst.com/sites/cleanfix.fr-624e5083-60c1-47dd-8045-ca4376797328/consent.js",
        "www.cleanfix.fr": "https://consent.cookiefirst.com/sites/cleanfix.fr-624e5083-60c1-47dd-8045-ca4376797328/consent.js",
    };
    const scriptUrl = cookieFirstScripts[currentDomain];

    if (scriptUrl) {
        const scriptElement = document.createElement("script");
        scriptElement.src = scriptUrl;
        scriptElement.async = true;

        document.head.appendChild(scriptElement);
        console.log("CookieFirst script loaded:", scriptUrl);
    } else {
        console.warn("No CookieFirst script found for the current domain:", currentDomain);
    }
})();
