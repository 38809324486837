import Alpine from 'alpinejs';

Alpine.data('responsiveTable', () => ({

    init() {

        let item = this.$el;

        item.querySelectorAll('table').forEach(table => {
            table.outerHTML = '<div class="overflow-x-auto">' + table.outerHTML + '</div>';
        });
    }

}));
