import Alpine from "alpinejs";

Alpine.data('priceFetcher', (productId, itemNo, customerNo, fetchUrl, priceElementId, productType) => ({
    productId,
    itemNo,
    customerNo,
    fetchUrl,
    priceElementId,
    productType,
    isFetching: false,

    init() {
        this.fetchPrice();
    },

    async fetchPrice() {
        if (this.isFetching) return;
        this.isFetching = true;

        try {
            const response = await fetch(this.fetchUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    productId: this.productId,
                    itemNo: this.itemNo,
                    customerNo: this.customerNo,
                    productType: this.productType,
                    quantity: 1,
                }),
            });

            if (!response.ok) {
                if (response.status === 403) {
                    console.warn('Guest users cannot fetch prices.');
                    return;
                }
                throw new Error(`Error fetching price: ${response.statusText}`);
            }

            const data = await response.json();
            this.updatePriceDisplay(data);

        } catch (e) {
            console.error('Failed to fetch updated price: ', e);
        } finally {
            this.isFetching = false;
        }
    },

    updatePriceDisplay(data) {
        const priceElement = document.getElementById(this.priceElementId);
        if (!priceElement) return;

        let newPrice = '';
        if (typeof data.amount !== 'undefined' && parseFloat(data.amount) > 0) {
            newPrice = new Intl.NumberFormat('de-CH', {
                style: 'currency',
                currency: 'CHF',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format(data.amount);
        }
        priceElement.textContent = newPrice;
    }
}));
