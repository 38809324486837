import Alpine from 'alpinejs';
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

Alpine.data('swiperSlideshow', () => ({

    init() {
        if (this.$store.system.editMode) {
            return;
        }

        new Swiper(this.$refs.container, {
            slidesPerView: 1,
            autoHeight: true,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
            },
            speed: 1000,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
    }
}));
